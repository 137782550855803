import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import TitleProduct from "../../objects/TitleProduct"
import BannerProduct from "../../components/BannerProduct"
import CardProduct from "../../components/CardProduct"
import CardsSection from "../../components/CardsSection"
import FreeShippingSection from "../../components/FreeShippingSection"
import { cards } from "./content"

const Espreguicadeiras = () => {

   const imgContent = useStaticQuery (
      graphql`
         query {
            imgBanner: file(relativePath: { eq: "banner-espreguicadeira.jpg" }) {
               childImageSharp {
                  fluid(maxHeight: 515) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            imgCards: allFile(
                  sort: {order: ASC, fields: 
                     childImageSharp___fluid___originalName},
                  filter: {relativeDirectory: {eq: "espreguicadeiras"}}) {
               edges {
                 node {
                   childImageSharp {
                     fluid(maxWidth: 576) {
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
               }
            }
         }
      `
   )

   const imagesCards = imgContent.imgCards.edges

   return (
      <Layout>
         <SEO 
            title="ESPREGUIÇADEIRAS"
            description="Para sua casa e para hotéis e spas. Coloridas e muito confortáveis e decorativas em fibra natural e sintética. Ideal para piscinas, hotéis, clubes e spas."
            image={imgContent.imgBanner.childImageSharp.fluid.src}
         />
         <Header title="" />
         <TitleProduct
            title="Espreguiçadeiras"
            subtitle="Para sua casa e para hotéis e spas."
            description="Coloridas e muito confortáveis e decorativas em fibra natural e sintética.
            Ideal para piscinas, hotéis, clubes e spas." />
         <BannerProduct 
            imgFluid={imgContent.imgBanner.childImageSharp.fluid}
         />
         <CardsSection>
            {imagesCards.map((img, i) => {
               return (
                  <CardProduct 
                     key={i}
                     target={cards[i].target}
                     imgFluid={img.node.childImageSharp.fluid}
                     alt={cards[i].alt}
                     title={cards[i].title}
                     description={cards[i].description}
                  />
               )
            })}
         </CardsSection>
         <FreeShippingSection />
         <Footer />
      </Layout>
   )
}

export default Espreguicadeiras;