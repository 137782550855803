const cards = [
   {
      target: "",
      alt: "Espreguiçadeira Cataratas",
      title: "Espreguiçadeira Cataratas",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeiras Bege",
      title: "Espreguiçadeiras Bege",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeira com braço",
      title: "Espreguiçadeira com braço",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeira Cataratas-trama-listra",
      title: "Espreguiçadeira Cataratas",
      description: "Trama listra",
   },
   {
      target: "",
      alt: "Espreguiçadeira Fechada",
      title: "Espreguiçadeira Fechada",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeira Caiobá",
      title: "Espreguiçadeira Caiobá",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeira",
      title: "Espreguiçadeira",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeira",
      title: "Espreguiçadeira",
      description: "",
   },
   {
      target: "",
      alt: "Espreguiçadeira em madeira Primavera",
      title: "Espreguiçadeira Primavera",
      description: "Em madeira",
   },
   {
      target: "",
      alt: "Espreguiçadeira em madeira Primavera com bandeja",
      title: "Espreguiçadeira Primavera com Bandeja",
      description: "Em madeira",
   },
]

export { cards }